<template>
  <div class="container-fluid bg-light">
    <!-- REG HORIZON -->
    <div class="row text-white bg-kpmg-dark-blue py-4 mb-4">
      <div class="col">
        <h1 class="kstyle">About Regulatory Oglibations</h1>
      </div>
    </div>
    <b-row>
      <b-col>
        <b-container>
          <div>Decomposition of regulations into obligations. Contact Lucas Ocelewicz for more information.</div>
        </b-container>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  created: function () {
    document.title = "About Obligations"
    this.$store.commit('setBrand', 'Obligations')
    this.$stat.log({ page: 'about', action: 'open about' })
  }
}
</script>
